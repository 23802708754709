<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-button">
          <q-btn-group outline >
            <c-btn
              v-if="editable&&!popupParam.disabled"
              :url="updateUrl"
              :isSubmit="isSave"
              :param="popupParam.select"
              mappingType="PUT"
              label="저장"
              icon="save"
              @beforeAction="saveInfo"
              @btnCallback="saveCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <c-text
              :disabled="popupParam.disabled"
              :editable="editable"
              label="점검결과"
              name="checkResult"
              v-model="popupParam.select.checkResult">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table2"
      class="q-mt-sm"
      :title="popupParam.select.sopFireFightingId ? `[${popupParam.select.fireFightingName}] 점검항목` : '점검항목'"
      :columns="gridResult.columns"
      :data="popupParam.select.results"
      :gridHeight="gridHeight"
      :editable="editable&&!popupParam.disabled"
      :filtering="false"
      :columnSetting="false"
      :isExcelDown="false"
      :usePaging="true"
      :gridHeightAuto="true"
      selection="multiple"
      rowKey="sopFireFightingCheckTargetResultId"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import mobileConfig from '@/js/mobileConfig';
export default {
  name: 'fireFightingCheckResult',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        select: {
          type: Object,
          default: () => ({
            sopFireFightingId: '',
            sopFireFightingCheckTargetId: '',
            sopFireFightingKindCd: '',
            fireFightingName: '',
            editFlag: '',
            results: [],
          }),
        },
        disabled: {
          type: Boolean,
          default: false,
        },
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      gridResult: {
        columns: [
          {
            name: 'itemName',
            field: 'itemName',
            label: '점검항목',
            align: 'left',
            style: 'width:40%',
            sortable: false,
          },
          {
            name: 'sopFireFightingCheckResultCd',
            field: 'sopFireFightingCheckResultCd',
            label: '점검결과',
            align: 'center',
            style: 'width:20%',
            sortable: false,
            type: 'select',
            codeGroupCd: 'SOP_FIRE_FIGHTING_CHECK_RESULT_CD',
            value: '',
            setHeader: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:40%',
            sortable: false,
            type: 'textarea'
          },
        ],
      },
      editable: false,
      isSave: false,
      detailUrl: '',
      updateUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    gridHeight() {
      return this.contentHeight ? String(this.contentHeight - 250) + 'px' : '500px';
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = mobileConfig.sop.fft.fireFightingCheck.target.get.url
      this.updateUrl = mobileConfig.sop.fft.fireFightingCheck.target.update.url
      // code setting
      // list setting
    },
    getTarget() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.select.sopFireFightingCheckTargetId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.popupParam.select, _result.data)
      },);
    },
    saveInfo() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.popupParam.select.chgUserId = this.$store.getters.user.userId;

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getTarget()
    },
  }
};
</script>